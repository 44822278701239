
import { Component, Vue, Watch } from "vue-property-decorator";
import { UserService, GaodeService, WechatService } from "src/services";
import { Toast } from "vant";
import { tools } from "src/utils";
import { ILogin } from "src/model";
import { DesSecretUtils } from "src/utils";
// import sensorsUtils from "src/utils/sensorsUtils"
import dayjs from "dayjs";

@Component
export default class Login extends Vue {
  /**
   * sso编号
   * @private
   * @returns string
   */
  private get s(): string {
    return (this.$route.query && (this.$route.query.s as string)) || "";
  }

  /**
   * 登录数据模型
   * @private
   * @returns ILogin
   */
  private modelData: ILogin = {
    code: "", // 企业编号
    account: "", // 登入账号
    password: "", // 登入密码
  };

  /**
   * 当路由发生变化的时候操作。
   * @protected
   * @param {any} - to 当前路由实例。
   * @returns {void}
   */
  protected async created(to: any) {
    try {
      let s = localStorage.getItem("s");
      let isWeatchMini = await this.getMiniProgram();
      if (s && s == this.s && isWeatchMini) {
        (wx.miniProgram as any).navigateBack();
      } else if (s && s == this.s) {
        history.go(-1);
      } else {
        this.modelData.code = localStorage.getItem("appid");
        // 获取机构信息
        this.getOrgInfo(this.modelData.code);
        this.onLogin();
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 判断是微信小程序环境
   * @private
   * @returns boolean
   */
  private getMiniProgram() {
    if (/micromessenger/i.test(navigator.userAgent)) {
      return new Promise(function (resolve, reject) {
        (wx.miniProgram as any).getEnv((res) => {
          if (res.miniprogram) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    } else {
      return new Promise(function (resolve, reject) {
        resolve(false);
      });
    }
  }

  /**
   * 获取机构详情
   * @private
   * @returns void
   */
  private async getOrgInfo(code: string): Promise<void> {
    try {
      let { content: result } = await UserService.instance.getOrgInfo(code);
      if (result.data) {
        this.$store.dispatch("setOrgInfo", result.data);
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 登录
   * @private
   * @returns void
   */
  private async onLogin(): Promise<void> {
    try {
      let { content: result } = await UserService.instance.SSOLogin({
        key: this.s,
        code: this.modelData.code,
      });
      localStorage.setItem("s", this.s.toString());
      // 绑定神策用户数据
      // sensorsUtils.bind("identity_Id",result.data.userId +"");
      if (result.data) {
        this.$store.dispatch("setUserInfo", result.data);
        this.$nextTick(() => {
          if (result.data.page) {
            try {
              let page = result.data.page;
              localStorage.removeItem("toPage");
              this.$router.replace({ name: "home", query: { toPage: page } });
            } catch {
              this.$router.replace({ name: "home" });
            }
          } else {
            this.$router.replace({ name: "home" });
          }
        });
      }
    } catch (err) {
      Toast(err);
    }
  }
}
